import mem from 'mem';
import { refreshTokenAPI } from './authServices';

const refreshToken = async () => {
  const refreshToken = sessionStorage.getItem('refresh-token');
  try {
    const result = await refreshTokenAPI(refreshToken).then((res: any) => {
      return res;
    });
    const newToken = result?.data?.accessToken;
    const newRefreshToken = result?.data?.refreshToken;

    if (!newToken) {
      sessionStorage.removeItem('access-token');
      sessionStorage.removeItem('user');
    }

    sessionStorage.setItem('access-token', JSON.stringify(newToken));
    sessionStorage.setItem('refresh-token', newRefreshToken);
    return newToken;
  } catch (err) {
    sessionStorage.removeItem('access-token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('refresh-token');
  }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshToken, {
  maxAge,
});
