import React, { useEffect, useState, useContext } from 'react';
import {
  AddMembersConfiguration,
  AvatarStyle,
  CometChatConversations, CometChatMessages,
  CometChatThemeContext,
  CometChatUIEvents,
  CometChatUIKit,
  CometChatUIKitConstants,
  ConversationsStyle, DetailsConfiguration, MessageBubbleAlignment, MessageComposerConfiguration,
  MessageComposerStyle, MessageHeaderConfiguration, MessageListConfiguration, MessagesStyle
} from '@cometchat/chat-uikit-react';
import { Badge, Box, Button, CardMedia, CircularProgress, Dialog, DialogContent, Grid, 
  IconButton, InputLabel, MenuItem, Tab, TextField, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import CloseIcon from '@mui/icons-material/Close';
import ConversationListView from './ConversationListView';
import TabPanel from './TabPanel';
import { setChatConversationStatus, getChartId, getUnassignedConversations, getVMANames,
  assignConversationToOthers, assignConversationToSelf } from 'store/slices/secureMessageSlice';
import { useAppDispatch } from 'store/hooks';
import { createComponent } from '@lit-labs/react';
import { CometChatTextBubble } from '@cometchat/uikit-elements';
import CreateTask from './Task/CreateTask';
import TaskList from './Task/TaskList';
import {CometChat} from '@cometchat/chat-sdk-javascript';
import { toast } from 'react-toastify';
import { AuthContext } from 'utils/AuthContext';

const ChatboxAdmin = (props: any) => {
  const [chatWithGroup, setChatWithGroup] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [guardianName, setGuardianName] = useState<any>('');
  const [patientId, setPatientId] = useState<any>();
  const [showTaskModal, setShowTaskModal] = useState<boolean>(false);
  const [showTaskList, setShowTaskList] = useState<boolean>(false);
  const [chartId, setChartId] = useState<any>(null);
  const [groupId, setGroupId] = useState<string>();
  const [editData, setEditData] = useState<any>(null);
  const [patientAvailable,setPatientAvailable] = useState(false);
  const [shortcuts, setShortcuts] = useState<any>();
  const [unassignedConversations, setUnassignedConversations]= useState<any>(null);
  const [showAssignToModal, setShowAssignToModal] = useState<boolean>(false);
  const [vamNames, setVMANames] = useState<any>();
  const [selectedVMA, setSelectedVMA] = useState();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
  const [msgTxt,setMsgTxt]=useState<string>('');
  const [selectedPatientName, setSelectedPatientName] = useState<any>('');
  const [userId, setUserId] = useState<any>('');
 

  const dispatch = useAppDispatch();
  const conversationStatus = true;
  const user = useContext(AuthContext);
  const { theme } = useContext(CometChatThemeContext);
  
  useEffect(() => {
    CometChat.callExtension('message-shortcuts', 'GET', 'v1/fetch', '')
      .then(shortcuts => {        
        setShortcuts(shortcuts);
      });
    getUnassignedMessages();  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientAvailable]);

  const getUnassignedMessages=()=>{
    dispatch(getUnassignedConversations({}))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          setUnassignedConversations(response?.data);
        }
      });
  };

  const handleConversation=()=>{
    setShowConfirmationDialog(true);
  };

  const handleCloseConversation = () => {
    setSelectedTab(3);
    const data = {
      groupId: groupId,
      indicator: 'end'
    };
    dispatch(setChatConversationStatus(data))
      .unwrap()
      .then((res: any) => {
        if (res.status === 200) {
          setChatWithGroup(null);
          setSelectedTab(0);
          setShowConfirmationDialog(false);
        }
      });    
  };

  const handleAssignTask = () => {
    setEditData(null);
    setShowTaskModal(!showTaskModal);
  };

  const handleEditTask=(data:any)=>{
    setShowTaskModal(true);
    setEditData(data);
  };

  function getMentionsTextFormatter(textMessage: CometChat.TextMessage) {  
    const mentionsFormatter =  
        CometChatUIKit.getDataSource().getMentionsTextFormatter({ theme });  
    mentionsFormatter.setCometChatUserGroupMembers(textMessage.getMentionedUsers());  
    mentionsFormatter.setMessage(textMessage);  

    mentionsFormatter.setMessageBubbleAlignment(MessageBubbleAlignment.left);  

    return mentionsFormatter;  
  }  

  function getUrlTextFormatter(textMessage: CometChat.TextMessage) {  
    const urlFormatter =  
        CometChatUIKit.getDataSource().getUrlTextFormatter({  
          theme,  
          alignment: MessageBubbleAlignment.left,
        
          
        }); 
    return urlFormatter;  
  }

  const TextMessageBubble = createComponent({
    tagName: 'cometchat-text-bubble',
    elementClass: CometChatTextBubble,
    react: React
  });

  const auxButton = (item: CometChat.User | CometChat.Group, composerId: any) => {
    return (
      <>
        <Button variant='text' onClick={() => handleConversation()} 
          sx={{opacity:0.7, fontSize:'14px'}}>
          {conversationStatus ? 'CLOSE CONVERSATION' : 'START CONVERSATION'}
        </Button>
        <Button variant='text' onClick={()=> handleAssignTask()} 
          sx={{marginRight:'20px', opacity:0.7, fontSize:'14px'}}>
          ASSIGN TASK
        </Button>
      </>
    );
  };


  const handlePatientClick = (item: any, patientId?:string, name?:string, guardianName?:string, userId?:string) => {

    setGuardianName(guardianName); // this is user/guardian name 
    setSelectedPatientName(name); // this is actual patient name
    setChatWithGroup(item.conversationWith);
    setGroupId(item?.conversationWith?.guid);
    setPatientId(patientId);
    setUserId(userId);
    dispatch(getChartId(patientId))
      .unwrap()
      .then((res: any) => {
        if (res.status === 200) {
          setChartId(res?.data?.chartId);
        }
      }); 
  };

  const getInternalGroups = (item: any) => {
    const groupName = item.conversationId.split('_');
    return (
      <>{groupName[1] === 'internal' ?
        <Grid container>
          <Grid item
            sx={{
              borderRadius: '50%', background: '#FFECC0',
              color: '#000', padding: '10px',
              fontSize: '14px', fontWeight: 700,
              height: '36px', width: '36px',
              cursor: 'pointer'
            }}>
            {groupName[1]?.charAt(0)?.toUpperCase()}{groupName[2]?.charAt(0)?.toUpperCase()}
          </Grid>
          <Grid item sx={{ padding: '10px', textTransform: 'capitalize' }}>
            <Badge badgeContent={item?.unreadMessageCount}
              sx={{
                '& .MuiBadge-badge': {
                  color: '#fff',
                  backgroundColor: '#FFC44D!important'
                }
              }} >
              <Typography sx={{ fontSize: '14px', fontWeight: 700, cursor: 'pointer' }}
                onClick={() => handlePatientClick(item)}>
                {groupName[2]}</Typography>
            </Badge>
          </Grid>
        </Grid> : ''}
      </>
    );
  };


  const getPatientListView = (item: any) => {
    const patientName = item.conversationId.split('_');
    if(patientName[1] !== 'internal' ){
      setPatientAvailable(true);
    }
    return <ConversationListView 
      conversationId={item?.conversationWith?.guid} 
      patientName={patientName[1]} // this is used to check whether it is internal group or not 
      unreadMessageCount={item?.unreadMessageCount}
      handlePatientClick={(id:any, name:string,guardianName:string, userId:string)=>
        handlePatientClick(item, id, name,guardianName, userId)}
    />;
  };

  const handleShowAssignToModal=(groupId:any)=>{
    setShowAssignToModal(true);
    setGroupId(groupId);
    dispatch(getVMANames({}))
      .unwrap()
      .then((res: any) => {
        if(res.status === 200){
          setVMANames(res?.data);
        }
      });
  };
  
  const UnassignedConversations=()=>{
    return(
      <>    
        {unassignedConversations.map((u:any) =>{
          return(
            <Grid container key={u.groupId} sx={{borderBottom:'1px solid #EAECF0'}} >
              <Grid item
                xs={1.8}
                sx={{
                  borderRadius: '50%', background: '#FFECC0',
                  color: '#000', padding: '10px',
                  fontSize: '14px', fontWeight: 700,
                  height: '36px', width: '36px',
                  cursor: 'pointer'
                }}>
                {u?.guardianName?.charAt(0)?.toUpperCase()}{u?.guardianName?.split(' ')[1]?.charAt(0)?.toUpperCase()}
              </Grid>
              <Grid item xs={10.2} sx={{ padding: '10px 0px 10px 10px', textTransform: 'capitalize' }}>
                <Grid container>
                  <Grid item md={user?.user?.roleType === 'Global Manager' ? 9 : 6}>
                    <Typography sx={{fontSize: '14px', fontWeight: 700, cursor: 'pointer'}}>
                      {u?.guardianName}
                    </Typography>
                    <Typography sx={{fontSize: '12px', fontWeight: 400, cursor: 'pointer'}}>
                      {u?.type}
                    </Typography>
                  </Grid>
                  <Grid item md={user?.user?.roleType === 'Global Manager' ? 3 : 6}>
                    { user?.user?.roleType === 'Global Manager' ?
                      <Button variant='text' onClick={() => handleShowAssignToModal(u.groupId)}>Assign</Button> :
                      <Button variant='text' onClick={() => handleAssignConversationToSelf(u.groupId)} 
                        sx={{ padding: '9px 0px' }}>
                        Assign to Me</Button>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}    
        
      </>
    );
  };
  const composerStyle = new MessageComposerStyle({
    inputBorder: 'none',
    textColor: '#000',
    placeHolderTextColor: '#fff',
    dividerTint: '#EAECF0',
    textFont:'Graphik-Regular,sans-serif'
  });

  const handleComposerTextChange =(e:any)=>{
    setMsgTxt(e);
    if(e.charAt(0)==='!'){
      // eslint-disable-next-line array-callback-return
      Object.keys(shortcuts.shortcuts).filter((key) => {
        if (key === e) {
          CometChatUIEvents.ccComposeMessage.next(shortcuts.shortcuts[key]);
        }
      });
    }   
  };

  const messageComposerConfig = new MessageComposerConfiguration({
    messageComposerStyle: composerStyle,
    hideVoiceRecording: true,
    hideLiveReaction: true,
    hideLayoutMode: true,
    AIIconURL: 'none',
    emojiIconURL: 'none',
    headerView: ()=>{<></>;},
    auxilaryButtonView:
      (item: CometChat.User | CometChat.Group, composerId: any) => {
        return selectedTab === 0 ? auxButton(item, composerId) : null;
      },
    onTextChange: (e: any) => handleComposerTextChange(e),
    sendButtonView:(...args:any)=>{
      return <button onClick={()=>{
        CometChatUIEvents.ccComposeMessage.next('');
        let receiverID = '';
        const messageText = msgTxt;
        let receiverType: string = CometChat.RECEIVER_TYPE.USER;
        if(args[1].group){
          receiverType=CometChat.RECEIVER_TYPE.GROUP;
          receiverID=args[1].group;
        }
        else{
          receiverType=CometChat.RECEIVER_TYPE.USER;
          receiverID=args[1].user;
        }

        const textMessage: CometChat.TextMessage = new CometChat.TextMessage(
          receiverID,
          messageText,
          receiverType
        );
        CometChatUIKit.sendTextMessage(textMessage).then(
          (message: any) => {
            setMsgTxt('');
          },
          (error: CometChat.CometChatException) => {
            console.log('Message sending failed with error:', error);
          }
        );
      }}>
        <CardMedia component={'img'} src='../../images/send.svg' alt='card-img1'
          sx={{ height: '20px', width: '20px', mb: '3px', border:'none!important', background:'none!important',
            cursor:'pointer'}} />

      </button>;
    },
  });

  const messageStyle = new MessagesStyle({
    background: '#fff',
  });

  const getConversationStyle = new ConversationsStyle({
    border: 'none',
    background: '#fff',
  });

  const getTemplates = () => {
    const templates = CometChatUIKit?.getDataSource()?.getAllMessageTemplates();
    templates?.forEach((template) => {
      // eslint-disable-next-line max-len
      if (template.contentView && template.category === CometChatUIKitConstants.MessageCategory.message && template.type === CometChatUIKitConstants.MessageTypes.text) {
        // eslint-disable-next-line max-len
        const contentView = template.contentView = (message: CometChat.BaseMessage, _alignment: MessageBubbleAlignment) => {
          const style = {
            textFont: 'inter',
            textColor: 'black'
          };
          return <TextMessageBubble text={(message as CometChat.TextMessage).getText()} textStyle={style} 
            textFormatters={[getUrlTextFormatter(message as CometChat.TextMessage), 
              getMentionsTextFormatter(message as CometChat.TextMessage)]}/>;
        };

        template.contentView = (message: CometChat.BaseMessage, _alignment: MessageBubbleAlignment) => {
          if (message?.getSender()?.getRole() === 'guardian') {
            return <div style={{
              background: '#FFC44D',
              padding: '3px',
              borderRadius: '0px 12px 12px 12px',
              alignItems: 'center',
              color: '#000!important'
            }}>{contentView(message, _alignment)}</div>;
           
          } else {
            return <div style={{
              background: '#FEF0C7',
              padding: '3px',
              borderRadius: '12px 0px 12px 12px',
              alignItems: 'center',
              color: '#000!important'
            }}>
              {contentView(message, _alignment)}</div>;
          }
        };
      }
    });
    return templates;
  };

  const messageListConfig = new MessageListConfiguration({
    templates: getTemplates()
  });

  // eslint-disable-next-line max-len
  const roles:any = ['clinical_manager','clinical_vma','vma', 'billing_vma','vma_manager', 'billing_manager', 'global_manager', 'provider', 'miscellaneous'];
  const addMemberConfig = new AddMembersConfiguration({ 
    usersRequestBuilder: new CometChat.UsersRequestBuilder().setLimit(30).setRoles(roles)
  });

  const detailsConfig = new DetailsConfiguration({
    addMembersConfiguration : addMemberConfig
  });

  const headerAvatarStyle = new AvatarStyle({
    backgroundColor: '#FEF0C7',
    nameTextColor: '#000'
  });


  const headerConfig = new MessageHeaderConfiguration({
    avatarStyle: headerAvatarStyle,
    listItemView: () => {
      return(<>
        <Grid container>
          <Grid item xs={5.5}>
            <Typography variant='h3' sx={{ textTransform: 'capitalize' }}>
              {guardianName} <br />
              {selectedTab === 0 ? 
                <Typography variant='body1'>
                  {selectedPatientName !== ' ' ? `${selectedPatientName} (Chart Id: ${chartId})` : 'N/A'}  
                 &nbsp;</Typography>  
                : ''}
            </Typography>
          </Grid>
          {selectedTab === 0 ?
            <Grid item xs={6.5} sx={{ textAlign: 'right' }}>
              <Button variant='text' onClick={() => setShowTaskList(true)}>View Task</Button>
            </Grid> : null}
        </Grid>
      </>);},
  });

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
    setChatWithGroup(null);
  };

  const handleAssignConversation=()=>{
    const data = {
      groupId,
      id:selectedVMA
    };
    dispatch(assignConversationToOthers(data))
      .unwrap()
      .then((response:any) =>{
        if(response.status === 200){
          toast('Conversation Assigned',{
            type: 'success',
            icon: true,
          });
          getUnassignedMessages();
          setShowAssignToModal(false);
        }
      });
  };

  const handleAssignConversationToSelf=(id:any)=>{
    dispatch(assignConversationToSelf(id))
      .unwrap()
      .then((response:any) =>{
        getUnassignedMessages();
      });
  };

  const handleVMAChange=(e:any)=>{
    setSelectedVMA(e.target.value);
  };
  return (
    <>
      
      <Grid container spacing={2}>
        <Grid item md={3}
          sx={{
            minHeight: '550px',
            boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
            background: '#fff'
          }}>
          <>
            {selectedTab === 3 ?
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: '50vh' }}
              >
                <Grid item xs={3}>
                  <CircularProgress/>
                </Grid>
              </Grid>
              :
              <>
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  sx={{
                    background: '#F9FAFB', borderRadius: '23px', marginTop: '10px',
                    '.MuiTab-root.Mui-selected': {
                      background: '#9F84FF',
                      color: '#fff',
                      borderRadius: '23px',
                      fontWeight: 500
                    }
                  }}
                  TabIndicatorProps={{
                    style: {
                      background: 'transparent',
                    }
                  }}
                  centered
                >
                  <Tab
                    label={'Guardian'}

                  />
                  <Tab
                    label={'Unassigned'}
                  />
                  <Tab
                    label={'Internal'}
                  />
                </Tabs>
                <TabPanel value={selectedTab} index={0}>

                  <CometChatConversations
                    listItemView={getPatientListView}
                    conversationsStyle={getConversationStyle}
                  />
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                  <UnassignedConversations />
                </TabPanel>
                <TabPanel value={selectedTab} index={2}>
                  <CometChatConversations
                    listItemView={getInternalGroups}
                    conversationsStyle={getConversationStyle}
                  />
                </TabPanel>
                
              </>
            }
          </>
        </Grid>
        <Grid item md={9}
          sx={{ display: 'flex', height: 'calc(100vh - 65px)' }}
        >
          {chatWithGroup !== null ?
            <CometChatMessages
              group={chatWithGroup}
              messagesStyle={messageStyle}
              messageHeaderConfiguration={headerConfig}
              messageComposerConfiguration={messageComposerConfig}
              messageListConfiguration={messageListConfig}
              detailsConfiguration={detailsConfig}
            /> :
            <>
              <Box sx={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center', margin: '0 auto' }}>
                <Typography variant='h2' color={'#000'} > 
                  {patientAvailable ? 'Select group to start chat' : 'No chats available'}</Typography>
              </Box>
            </>
          }
        </Grid>
      </Grid>

      {showTaskModal && <CreateTask onClose={handleAssignTask} 
        editData={editData !== null ? editData : null}
        userId={userId} 
        patientId={patientId}
        patientName = {editData === null ? selectedPatientName : null}
      />}
      {showTaskList && <TaskList patientId={patientId} onEdit={handleEditTask} onClose={()=>setShowTaskList(false)}/>}
    
      <Dialog open={showAssignToModal} onClose={() => setShowAssignToModal(false)}>
        <IconButton
          aria-label='close'
          onClick={() => setShowAssignToModal(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h2' sx={{ borderBottom: '1px solid #EAECF0' }} textAlign={'center'} pb={2}>
              Assign Conversation
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor='assignedTo'>Assign To*</InputLabel>
              <TextField 
                id='assignTo'
                variant='outlined' 
                fullWidth 
                select
                label='Select VMA'
                onChange={(e:any) => handleVMAChange(e)}
              >
                {vamNames?.length && vamNames.map((v:any) =>{
                  return(
                    <MenuItem key={v.firstName+v.lastName} value={v.userId}>
                      {v.firstName+' '+v.lastName}</MenuItem>
                  );
                })}                
              </TextField>                            
            </Grid>
            <Box sx={{justifyContent:'center', justifyItems:'center', m:'45px auto 5px'}}>
              <Button variant='contained' onClick={() => handleAssignConversation()}>
                Assign
              </Button>
            </Box>
            
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={showConfirmationDialog} onClose={() => setShowConfirmationDialog(false)}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h3' textAlign={'center'} pb={2}>
              Close Conversation?
              </Typography>
            </Grid>
            <Grid xs={12} textAlign={'center'} p='30px 40px'>
              <Typography sx={{fontSize:'14px'}}> 
              Are you sure you want to close this conversation? 
              </Typography>
            </Grid>
            <Box sx={{justifyContent:'center', justifyItems:'center', m:'25px auto 5px'}}>
              <Button variant='contained' size='small' 
                sx={{ marginRight: '8px', background: '#F04438',
                  '&:hover':{
                    background:'#f02719'
                  }
                }}
                onClick={() => handleCloseConversation()}
              >
                Yes
              </Button>
              <Button variant='outlined' size='small' onClick={()=>setShowConfirmationDialog(false)}>Cancel</Button>
            </Box>
            
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ChatboxAdmin;
