import React, { useState, useContext, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Container,
  CardMedia,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Grid,
  Typography,
  Box,
  Select,
  MenuItem,
} from '@mui/material';

import logo from 'assets/images/logo.svg';
import logoFull from 'assets/images/logo-mindweal.svg';
import logout from 'assets/images/log-out.svg';
import messages from 'assets/images/messages.svg';
import tasklist from 'assets/images/tasklist.svg';
import convolist from 'assets/images/convolist.svg';
import MenuIcon from '@mui/icons-material/Menu';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { ROUTE } from 'route/RouteEnums';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'utils/AuthContext';
import COLORS from 'theme/colors';
import { useAppDispatch } from 'store/hooks';
import { getSibling } from 'store/slices/userSlice';
import { CometChatUIKit } from '@cometchat/chat-uikit-react';

const Header = () => {
  const [drawer, setDrawer] = useState(false);
  const [siblings, setSiblings] = useState<any>();
  const [selectedSibling, setSelectedSibling] = useState('');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, setUserDetails } = useContext(AuthContext);
  const menuItems = [
    {
      label: 'Dashboard',
      link: ROUTE.DASHBOARD,
      icon: <GridViewOutlinedIcon sx={{ color: COLORS.DarkBlue }} />,
    },
    
    {
      label: 'Documents',
      link: ROUTE.DOCUMENTS,
      icon: <PictureAsPdfOutlinedIcon sx={{ color: COLORS.DarkBlue }} />,
    },
    {
      label: 'Treatment Plan',
      link: ROUTE.TREATMENT,
      icon: <LocalHospitalOutlinedIcon sx={{ color: COLORS.DarkBlue }} />,
    },
    {
      label: 'MCAT Result',
      link: ROUTE.MCAT_RESULT,
      icon: <TaskOutlinedIcon sx={{color: COLORS.DarkBlue}}/>
    },
    {
      label: 'My Profile',
      link: ROUTE.PROFILE_DASHBOARD,
      icon: <AccountCircleOutlinedIcon sx={{ color: COLORS.DarkBlue }} />,
    }  
  ];

  useEffect(() =>{
    if(!user?.isAdmin){
      getSiblings();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user?.patientId]);

  const getSiblings=async()=>{
    await dispatch(getSibling({}))
      .unwrap()
      .then(async(response:any) => {
        if(response?.status === 200){
          setSiblings(response?.data);
          const name = response?.data?.find((s:any) => s.id === user?.patientId);
          setSelectedSibling(name?.firstName+' '+name?.lastName);
          if(user?.addedNewPatient){
            CometChatUIKit.logout()
              .then((res: any) => {
                if(res.success){
                  CometChatUIKit.login(name?.cometUserId);
                }
              });              
          }          
          delete user.addedNewPatient;
          const data = { ...user,  patientId: name?.id, secureMessageUserId:name?.cometUserId };
          setUserDetails(data);
        }
      });
  };

  const menulist = () => (
    <Grid
      container
      sx={{ width: 250, height: '100%' }}
      role='presentation'
      flexDirection={'column'}
      justifyContent={'space-between'}
      position={'relative'}
    >
      <Grid item>
        <List>
          {user.isAdmin ? (
            <>
              <ListItem key={'admin'} disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(`${ROUTE.ADMIN_DASHBOARD}`);
                    setDrawer(false);
                  }}
                >
                  <ListItemIcon>
                    <GridViewOutlinedIcon sx={{ color: COLORS.DarkBlue }} />
                  </ListItemIcon>
                  <ListItemText primary={'Dashboard'} />
                </ListItemButton>
              </ListItem>
              <ListItem key={'messages'} disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(`${ROUTE.ADMIN_MESSAGES}`);
                    setDrawer(false);
                  }}
                >
                  <ListItemIcon>
                    <CardMedia component={'img'} alt='messages' src={messages} sx={{ width: '24px', height: 'auto' }} />
                  </ListItemIcon>
                  <ListItemText primary={'Messages'} />
                </ListItemButton>
              </ListItem>
              <ListItem key={'tasklist'} disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(`${ROUTE.ADMIN_TASKLIST}`);
                    setDrawer(false);
                  }}
                >
                  <ListItemIcon>
                    <CardMedia component={'img'} alt='tasklist' src={tasklist} sx={{ width: '24px', height: 'auto' }} />
                  </ListItemIcon>
                  <ListItemText primary={'Task List'} />
                </ListItemButton>
              </ListItem>

              { user?.roleType === 'Global Manager' ? 
                <ListItem key={'conversationList'} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(`${ROUTE.ADMIN_CONVERSATION_LIST}`);
                      setDrawer(false);
                    }}
                  >
                    <ListItemIcon>
                      <CardMedia component={'img'} alt='conversationList' src={convolist} 
                        sx={{ width: '24px', height: 'auto' }} />
                    </ListItemIcon>
                    <ListItemText primary={'Conversation List'} />
                  </ListItemButton>
                </ListItem> : null }
            </>
            
          ) : (
            menuItems.map((text, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(`${text.link}`);
                    setDrawer(false);
                  }}
                >
                  <ListItemIcon>{text.icon}</ListItemIcon>
                  <ListItemText primary={text.label} />
                </ListItemButton>
              </ListItem>
            ))
          )}
        </List>
      </Grid>
      <Grid item>
        <List sx={{ paddingTop: 'auto' }}>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate(`${ROUTE.SIGN_OUT}`)}>
              <ListItemIcon>
                <CardMedia component={'img'} alt='logout' src={logout} sx={{ width: '20px', height: 'auto' }} />
              </ListItemIcon>
              <ListItemText primary={'Log Out'} />
            </ListItemButton>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );

  const toggleDrawer = (toggle: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawer(toggle);
  };

  const handleSiblingChange=async(e:any)=>{
    await CometChatUIKit.logout();
    const firstName = e.target.value.split(' ')[0];
    const name = siblings.find((s:any) => s.firstName === firstName);
    setSelectedSibling(e.target.value);
    const data = { ...user, patientId: name?.id, secureMessageUserId:name?.cometUserId };
    setUserDetails(data); 
    if (window.location.pathname === '/m-cat/assessment' || window.location.pathname === '/wellbeing/assessment') {
      navigate(ROUTE.DASHBOARD);
    }
  };
  return (
    <>
      <SwipeableDrawer
        anchor={'left'}
        open={drawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{ zIndex: 1300 }}
      >
        {menulist()}
      </SwipeableDrawer>

      <AppBar elevation={1} position='sticky' color='inherit'>
        <Container maxWidth='xl'>
          <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <IconButton
              size='large'
              onClick={toggleDrawer(!drawer)}
              edge='start'
              color='inherit'
              aria-label='menu'
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <CardMedia
              component='img'
              image={logo}
              sx={{
                width: 'auto',
                height: '35px',
                margin: { md: 'auto' },
                display: { xs: 'block', sm: 'none' },
              }}
              alt='image'
            />
            <CardMedia
              component='img'
              image={logoFull}
              sx={{
                width: 'auto',
                height: '35px',
                margin: { md: 'auto' },
                display: { xs: 'none', sm: 'block' },
              }}
              alt='image'
            />
            { !user.isAdmin && siblings?.length > 1 ?
              <Box sx={{mr:'24px'}}>
              Switch Sibling 
                <Select
                  id='sibling'
                  sx={{height:'40px', borderRadius:'8px', ml:'8px', 
                    maxWidth:{xs:'100px', sm:'200px'}, 
                    minWidth:{xs:'100px', sm:'200px'}}}
                  value={selectedSibling}
                  onChange={(e:any) => handleSiblingChange(e)}
                >
                  {siblings?.map((s:any) =>{
                    return(
                      <MenuItem key={s.firstName+s.lastName} value={s.firstName+' '+s.lastName}>
                        {s.firstName+' '+s.lastName}</MenuItem>
                    );
                  })}
                  <MenuItem disabled sx={{width:'200px', whiteSpace:'normal'}}>
                You can add new sibling from patient profile </MenuItem>
                </Select>
              </Box> 
              : null }
            <IconButton
              size='small'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={() => navigate(`${user.isAdmin?ROUTE.ADMIN_PROFILE:ROUTE.PROFILE_DASHBOARD}`)}
              color='inherit'
              sx={{ background: '#F2F4F7', padding: '0', width: '54px', height: '54px', borderRadius: '50%' }}
            >
              <Typography variant='h3' sx={{ color: COLORS.Gray600 }}>
                {user?.firstName?.charAt(0).toUpperCase()}
                {user?.lastName?.charAt(0).toUpperCase()}
              </Typography>
              {/* <AccountCircle /> */}
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;
