import { useContext, useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Button, CardMedia, Container, Dialog, DialogContent, Grid, IconButton, InputLabel, MenuItem,
  TextField, Tooltip, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useLocation } from 'react-router';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { toast } from 'react-toastify';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { childInfoSchema } from 'utils/schema';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  addPatientDetails,
  getPatientDetails,
  getPatientDetailsFromAdmin,
  updatePatientDetails,
  getCityState,
  deleteSibling
} from 'store/slices/userSlice';
import { AuthContext } from 'utils/AuthContext';
import { Restricted } from 'components/Restricted';
import { permissions } from 'utils/constants';

const PatientInfo = (props: any) => {
  const { setUserDetails, user } = useContext(AuthContext);
  const [displayInsuranceMessage, setDisplayInsuranceMessage] = useState<boolean>(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [opentooltip, setOpenTooltip] = useState<boolean>(false);
  const [preferredLocation, setPreferredLocation] = useState<string>();
  const [showConfirmationDialog,setShowConfirmationDialog]= useState<boolean>(false);
  const [dateError, setDateError] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(childInfoSchema),
    mode: 'all',
  });
  const dispatch = useAppDispatch();
  const isProfileDetailsFetched = useAppSelector((state) => state.user.loaders.isProfileDetailsFetched);
  const { state } = useLocation(); 
  const locations = [
    {
      id:1,
      location:'24B Professional Park Dr, Maryville, IL - 62062',
      // eslint-disable-next-line max-len
      instruction:'Conveniently accessible from I-55 and I-270. Located at the intersection of IL-159 & IL-162 inside Maryville Professional Park.'
    },
    {
      id:2,
      location:'1315 Macom Dr, Suite 207, Naperville, IL - 60564',
      // eslint-disable-next-line max-len
      instruction:'Conveniently accessible from Route 59 and 83rd Street. We are in Suite 207, co-located with English Meadows Counseling Services'
    },
    {
      id:3,
      location:'19 E First Street, Hinsdale, IL - 60521',
      // eslint-disable-next-line max-len
      instruction:'Conveniently accessible from I-294. Located on 1st Street between S Washington Street and S Garfield Street. We are on the second floor in Suite D. Plenty of street parking and a free parking lot on Garfield Street; take the stairs to First Street. Just a 2-minute walk from the Hinsdale Metra Station on the BNSF Railway line.'
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setOpenTooltip(false);
      }     
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    //eslint-disable-next-line
  }, []);

  const tooltipContent = (
    <Typography>
      Storing your nearest MindWeal location helps us personalize your care, 
      offering you the most relevant services and updates from the office that is closest to you. 
      You can update your preferred office location anytime through this section. 
      It will apply to future appointments, while any existing bookings will remain at the previously selected location
    </Typography>
  );

  const getPatient = async () => {
    await dispatch(getPatientDetails(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          Object.keys(response?.data).forEach((key: any) => {
            if (response.data[key] === null) setValue(key, '', { shouldValidate: false });
            else setValue(key, response.data[key], { shouldValidate: false });
          });
          if(response?.data?.invalidInsurance){
            setDisplayInsuranceMessage(true);
          }
        } 
      });
  };
  const getPatientFromAdmin = async () => {
    await dispatch(getPatientDetailsFromAdmin(state?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          Object.keys(response?.data).forEach((key: any) => {
            if (response.data[key] === null) setValue(key, '', { shouldValidate: false });
            else setValue(key, response.data[key], { shouldValidate: false });
          });
        } 
      });
  };

  useEffect(() => {
    setValue('dateOfBirth', dayjs(user?.childBirthDate).format('MM/DD/YYYY'));
    if (props?.from === 'admin') getPatientFromAdmin();
    else  if(state?.patientId === 'new'){
      setValue('biologicalGender','');
      setValue('childAddress', '');
      setValue('city', '');
      setValue('dateOfBirth', '');
      setValue('firstName', '');
      setValue('lastName', '');
      setValue('preferredGender', '');
      setValue('preferredName', '');
      setValue('state', '');
      setValue('zipcode', '');
    }
    else getPatient();
    //eslint-disable-next-line
  }, [props]);

  const getPayload = (formData: any) => {
    const { id, firstName, lastName, dateOfBirth, biologicalGender, preferredGender, preferredName, childAddress,
      zipcode, state, city, preferredLocation} = formData;
    return {
      id: id ? id : user.patientId,
      firstName,
      lastName,
      dateOfBirth: dayjs(dateOfBirth).format('MM/DD/YYYY'),
      biologicalGender,
      preferredGender,
      preferredName,
      childAddress,
      zipcode, state, city,
      preferredLocation
    };
  };

  const addPatient = (formData: any) => {
    const payload = getPayload(formData);
    if(state?.patientId === 'new'){
      delete payload.id;
    }
    dispatch(addPatientDetails(payload))
      .unwrap()
      .then((response: any) => {
        if (response.status === 201) {
          const message = 'Patient details added successfully.';
          toast(message, {
            type: 'success',
            icon: true,
          });
          reset(formData);
        }
        const userData = {
          ...user,
          patientId: response?.data?.id,
          zipcode : payload?.zipcode,
          addedNewPatient:true
        };
        setValue('id', response?.data?.id, { shouldValidate: true });
        setUserDetails(userData);
        props?.onComplete();
      });
  };

  const updatePatient = (formData: any) => {
    const payload = getPayload(formData);
    dispatch(updatePatientDetails(payload))
      .unwrap()
      .then((response: any) => {
        if (response.status === 200) {
          const message = 'Patient details updated successfully.';
          toast(message, {
            type: 'success',
            icon: true,
          });
          reset(formData);
          const userData = {
            ...user,
            zipcode : payload?.zipcode
          };
          setUserDetails(userData);
          if(response?.data?.invalidInsurance){
            setDisplayInsuranceMessage(true);
            setTimeout(()=>{
              props?.onComplete();
            }, 10000);
          }else{
            props?.onComplete();
          }
        }
      });
  };

  const onSubmitForm = (formData: any) => {
    if(formData.dateOfBirth === ''){
      setDateError(true);
      return;
    }
    if(isValid && isDirty){
      if (formData.id && state?.patientId !== 'new') {
        updatePatient(formData);
      } else {
        addPatient(formData);
      }
    }else{
      toast('No changes to update.',{
        type:'info',
        icon:true
      });
    }
  };

  const handleZipcodeChange=(e:any)=>{    
    if(e.target.value.length === 5){
      dispatch(getCityState(e.target.value))
        .unwrap()
        .then((res: any) => {
          if (res.status === 200) {
            setValue('city', res?.data?.primaryCity, {shouldDirty:true});
            setValue('state', res?.data?.state, {shouldDirty:true});
            setValue('zipcode', e.target.value, {shouldDirty:true, shouldValidate:true});
          }
        })
        .catch((e: any) => {
          if(e.err.errorCode === 3006){
            toast('No service available in your area', {
              type: 'error',
              icon: true,
            });
            setValue('zipcode','', {shouldDirty:false});
            setValue('city','', {shouldDirty:false});
            setValue('state', '', {shouldDirty:false});
          }
        });
    }
  };

  const handlePreferredLocation=(e:any)=>{
    setValue('preferredLocation', e.target.value, {shouldDirty:true, shouldValidate:true});
    setPreferredLocation(e.target.value);
  };

  const handleDeleteSibling=()=>{    
    dispatch(deleteSibling(user?.patientId))
      .unwrap()
      .then((response:any) => {
        if(response.status === 200){
          toast('Sibling deleted successfully',{
            icon:true,
            type:'success'
          });
          setShowConfirmationDialog(false);
          props?.getSibling();
          
          // eslint-disable-next-line array-callback-return
          const filteredSibling = props.siblings.filter((s:any) =>{
            if(s.id !== user?.patientId)
            {
              return s;
            }
          });
          const s = {
            id: filteredSibling[0]?.id
          };
          props?.changeSibling(s);
        }
      });
  };

  const handleDateChange = (val: any) => {
    const data = dayjs(val).format('YYYY-MM-DD');
    const age = dayjs().diff(dayjs(data), 'month') / 12;
    if(new Date(val).getFullYear() > 1000){
      if (Number(age) < 18 && Number(age) > 4 ) {
        setValue('dateOfBirth', dayjs(val).format('MM/DD/YYYY'), {shouldValidate:false, shouldDirty:false});
        register('dateOfBirth');
        setDateError(false);
      } else{
        setDateError(true);
      }
    }    
  };

  return (
    <Container component='main' maxWidth='sm' key={props?.newSibling}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box component='form' noValidate sx={{ mt: 1 }} onSubmit={handleSubmit((formData) => onSubmitForm(formData))}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='firstName'>{props?.from !== 'admin' ? 'First Name*' : 'First Name'}</InputLabel>
              <TextField
                autoComplete='first-name'
                placeholder='Enter Patient’s First Name'
                required
                fullWidth
                id='firstName'
                autoFocus
                {...register('firstName')}
                error={!!errors.firstName}
                helperText={errors?.firstName?.message}
                disabled={props?.from === 'admin'}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='lastName'>{props?.from !== 'admin' ? 'Last Name*' : 'Last Name'}</InputLabel>
              <TextField
                required
                fullWidth
                placeholder='Enter Patient’s Last Name'
                id='lastName'
                autoComplete='last-name'
                {...register('lastName')}
                error={!!errors.lastName}
                helperText={errors?.lastName?.message}
                disabled={props?.from === 'admin'}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='preferredName'>
                {props?.from !== 'admin' ? 'Preferred Name*' : 'Preferred Name'}
              </InputLabel>
              <TextField
                required
                fullWidth
                placeholder='Enter Patient’s Preferred Name'
                id='preferredName'
                autoComplete='preferredName'
                {...register('preferredName')}
                error={!!errors.preferredName}
                helperText={errors?.preferredName?.message}
                disabled={props?.from === 'admin'}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='dateOfBirth'>
                {props?.from !== 'admin' ? 'Date of Birth*' : 'Date of Birth'}
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled = {state?.patientId !== 'new'}
                  sx={{ width: '100%' }}
                  disableFuture
                  onChange={(e) => handleDateChange(e)}
                  value={getValues('dateOfBirth') ? dayjs(getValues('dateOfBirth')) : null}
                />
              </LocalizationProvider>
              {dateError ? <Typography color='#d32f2f' sx={{fontSize:'12px'}}>
                 Patient&apos;s age must be between 4 to 18 </Typography> : null }
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='biologicalGender'>
                {props?.from !== 'admin' ? 'Biological Gender*' : 'Biological Gender'}
              </InputLabel>
              <TextField
                id='biologicalGender'
                select // tell TextField to render select
                fullWidth
                {...register('biologicalGender')}
                error={!!errors.biologicalGender}
                helperText={errors?.biologicalGender?.message}
                value={watch('biologicalGender') ? watch('biologicalGender') : ''}
                disabled={props?.from === 'admin'}
              >
                <MenuItem key={1} value='Male'>
                  Male
                </MenuItem>
                <MenuItem key={2} value='Female'>
                  Female
                </MenuItem>
                <MenuItem key={3} value='Other'>
                  Other
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='preferredGender'>Preferred Pronoun</InputLabel>
             
              <TextField
                id='preferredGender'
                select // tell TextField to render select
                fullWidth
                {...register('preferredGender')}
                value={watch('preferredGender') ? watch('preferredGender') : 'choose_preferred_gender'}
                disabled={props?.from === 'admin'}
              >
                <MenuItem key={0} value='choose_preferred_gender' disabled>
                  Choose Pronouns
                </MenuItem>
                <MenuItem key={1} value='He/Him'>
                 He/Him
                </MenuItem>
                <MenuItem key={2} value='She/Her'>
                She/Her
                </MenuItem>                
                <MenuItem key={3} value='They/Them'>
                They/Them
                </MenuItem>
                <MenuItem key={4} value='other'>
                  Other
                </MenuItem> 
              </TextField> 
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor='childaddress'>Street Address*</InputLabel>
              <TextField
                fullWidth
                multiline
                rows={3}
                placeholder='Enter patient&#39;s current address'
                id='childaddress'
                autoComplete='dchildaddressob'
                variant='filled'
                {...register('childAddress')}
                error={!!errors.childAddress}
                helperText={errors?.childAddress?.message}
                disabled={props?.from === 'admin'}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='zipcode'>
                {props?.from !== 'admin' ? 'Zip Code*' : 'Zip Code'}
              </InputLabel>
              <TextField
                required
                fullWidth
                placeholder='Enter Zip Code'
                id='zipcode'
                autoComplete='zipcode'
                {...register('zipcode')}
                error={!!errors.zipcode}
                helperText={errors?.zipcode?.message}
                disabled={props?.from === 'admin'}
                inputProps={{
                  maxLength: 5,
                }}
                onChange={(e:any) => handleZipcodeChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='city'>
                City
              </InputLabel>
              <TextField
                fullWidth
                placeholder='City'
                id='city'
                autoComplete='city'
                {...register('city')}
                error={!!errors.city}
                helperText={errors?.city?.message}
                disabled={props?.from === 'admin'}
                value={watch('city') ? watch('city') : ''}
              />
            </Grid> 
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='state'>
                {'State' }
              </InputLabel>
              <TextField
                required
                fullWidth
                placeholder='State'
                id='state'
                autoComplete='state'
                {...register('state')}
                error={!!errors.state}
                helperText={errors?.state?.message}
                disabled={true}
                inputProps={{
                  maxLength: 255,
                }}
                value = {watch('state') ? watch('state') : ''}
              />
            </Grid> 
            <Grid item xs={12}>
              <InputLabel htmlFor='preferredLocation'>
                {'Preferred Clinic Location*' }
                <Tooltip
                  ref={tooltipRef}
                  open={opentooltip}
                  title={tooltipContent}
                  arrow
                  placement='right-start'
                  enterTouchDelay={0}
                  enterDelay={0}
                  leaveDelay={8000}
                >
                  <InfoOutlinedIcon onMouseEnter={() => setOpenTooltip(true)}/>
                </Tooltip>
              </InputLabel>
              <TextField
                select
                required
                fullWidth
                
                placeholder='preferredLocation'
                id='preferredLocation'
                {...register('preferredLocation')}
                error={!!errors.preferredLocation}
                helperText={errors?.preferredLocation?.message}                
                value = {watch('preferredLocation') ? watch('preferredLocation') : ''}
                onChange={(e)=> handlePreferredLocation(e)}
                sx={{
                  '& .MuiSelect-select':{
                    whiteSpace:'normal'
                  }
                }}
              >
                <MenuItem key={0} value='preferredLocation' disabled>
                  Choose Location
                </MenuItem>
                {locations.map((l) => (
                  <MenuItem key={l.id} value={l.location} disabled={props?.from === 'admin'}>
                    {l.location}
                  </MenuItem>
                ))}
              </TextField>
              {
                // eslint-disable-next-line array-callback-return
                preferredLocation && locations.map((l:any) => {
                  if(preferredLocation === l.location){
                    return(
                      <Grid container key={l.id} 
                        sx={{mt:'12px', p:'12px', 
                          border:'1px solid #EAECF0', 
                          borderRadius:'8px',
                          background:'#F8F5FF'}} >
                        <Grid xs={0.5}>
                          <CardMedia 
                            component={'img'}
                            src='../images/location.png'
                            sx={{height:'17px', width:'14px'}}
                          />
                        </Grid>
                        <Grid xs={11.5} paddingLeft={'5px'}>
                          {l.instruction}
                        </Grid>                         
                      </Grid>
                    );
                  }                  
                })
              }
            </Grid>

            {displayInsuranceMessage && 
             <Grid item xs={12}>
               <Alert severity='error' sx={{fontSize:'14px'}}>
               Please review your insurance information is accurate
               </Alert>
              
             </Grid>
            }   
            <Restricted permission={permissions.EDIT_PROFILE}>
              <Grid container>
                { props.siblings.length > 1 ?
                  <Grid item xs={7} display={'flex'} sx={{justifyContent:{xs:'left',sm:'end'} }}>
                    <Button  variant='text' sx={{color:'#F04438'}} onClick={()=>setShowConfirmationDialog(true)}>
                   Delete
                    </Button>
                  </Grid> : null }
                <Grid item xs={props.siblings.length > 1 ? 5 : 12} display={'flex'} justifyContent ='end'>
                  <LoadingButton
                    loading={isProfileDetailsFetched}
                    variant='contained'
                    type='submit'
                    sx={{ mt: 2, mb: 2 }}
                  >
                  Save
                  </LoadingButton>
                </Grid>
              </Grid>
            </Restricted>
          </Grid>
        </Box>
      </Box>
      <Dialog open={showConfirmationDialog} onClose={() => setShowConfirmationDialog(false)}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h3' textAlign={'center'} pb={2}>
            Delete Sibling?
              </Typography>
            </Grid>
            <Grid xs={12} textAlign={'center'} p='30px 40px'>
              <Typography sx={{fontSize:'14px'}}> 
            Are you sure you want to delete this sibling? 
            This will permanently remove all associated data for&nbsp;  
                {watch('firstName')?.charAt(0)?.toUpperCase() + watch('firstName')?.slice(1)}. 
            This action cannot be undone.
              </Typography>
            </Grid>
            <Box sx={{justifyContent:'center', justifyItems:'center', m:'25px auto 5px'}}>
              <Button variant='contained' size='small' 
                sx={{ marginRight: '8px', background: '#F04438',
                  '&:hover':{
                    background:'#f02719'
                  }
                }}
                onClick={() => handleDeleteSibling()}
              >
              Delete
              </Button>
              <Button variant='outlined' size='small' onClick={()=>setShowConfirmationDialog(false)}>Cancel</Button>
            </Box>
          
          </Grid>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default PatientInfo;
